@import "../../_variables";
@import "../../shared/_components";

#sidebar{
  width: 90%;
  position: fixed;
  height: 100%;
  top: 0;
  z-index: 999;

  left: -100%;

  -webkit-transition: left .3s;
  transition: left .3s;

  #sidebar-main{
    width: 100%;
    height: 100%;
    background-color: #fff;
    float: left;
  }

  #sidebar-header{
    width: 100%;
    padding: $nav-padding;
    height: $nav-height;
    background-color: $nav-bg-color;
    color: #fff;

    #sidebar-close-btn-container{
      width: 35px;
      height: 100%;
      margin-right: 10px;
      float: left;

      #sidebar-close-btn{
        width: 100%;
        height: 100%;
        display: block;
        text-align: center;
        line-height: $nav-line-height;
      }
    }

    &:after{
      @include clear();
    }
  }

  #sidebar-content{
    padding: 10px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: $nav-height + 10px;
  }

  .sb-header{
    margin-bottom: 10px;
  }

  .sb-expander-list{
    .sb-expander{
      .sb-expander-toggle, .sb-expander-content{
        width: 100%;
      }

      .sb-expander-toggle {
        border: 1px solid #ddd;
        padding: 10px;
        padding-right: 40px;
        position: relative;
        background-color: #eee;

        .sb-toggle-marker{
          position: absolute;
          top: 50%;
          font-size: 0.8rem;
          margin: 0;
          margin-top: -0.4rem;
          right: 10px;
          opacity: 0.8;
        }
      }

      .sb-expander-toggle, .sb-expander-item{
        min-height: 40px;
        overflow: hidden;
        white-space: nowrap;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
      }

      .sb-expander-content{
        display: none;
        
        a{
          @include reset-link();
        }
        .sb-expander-item{
          display: block;
          border: 1px solid #ddd;
          border-top: none;
          padding: 10px 5px;
          padding-left: 30px;
        }
      }

      &.expand{
        .sb-expander-toggle{
          font-weight: 600;
        }

        .sb-expander-content{
          display: block;
        }
      }

    }

  }
}

#main.sidebar-open{
  overflow: hidden;

  #sidebar{
    left: 0;
    box-shadow: 5px 0 15px rgba(0,0,0,.2);
  }
}

.sidebar-shadow{
  top: 0;
  left: 0;
  position: fixed;
  z-index: 900;
  width: 100%;
  height: 100%;
  background-color: #333;
  opacity: 0;
}


@media screen and (min-width: $max-site-width){
  #sidebar{
    width: .9 * 768px;
  }
}