/**
 * HOKI Shop Website SCSS Variables
 * Front end website styling
**/

//  Site-wide Setup
$max-site-width: 768px;

//  Typography Setup
$default-font: 'Cabin', 'Segoe UI', 'Helvetica', 'Arial', sans-serif;
$default-font-color: #333;

//  Navigation Bar Setup
$nav-bg-color: #008fd0;
$nav-line-height: 35px;
$nav-padding: 10px;
$nav-height: $nav-line-height + 2 * $nav-padding;

//  Footer Setup
$back-to-top-bg-color: #005379;
$footer-bg-color: #00273a;

//  Home: Product Category Setup
$number-of-category-lines: 2;
$product-category-height: 60px;

//  Link
$default-link-color: #008fd0;
$default-link-hover-color: darken($default-link-color, 10%);

//  Product Display
$product-name-color: #333;
$normal-list-price-color: #008fd0;
$discount-price-color: #ad0000;
$discounted-list-price-color: #999;

$product-picture-height: 150px;
$product-name-height: 45px;
$product-price-height: 25px;


/**
 * Administration Page Styles
 */

//  Typography Setup
$a-default-font-color: #353944;

//  Sidebar Setup
$a-nav-height: 40px;
$a-nav-bg-color: #054679;

$a-loading-color: $nav-bg-color;

$a-sidebar-width: 225px;
$a-sidebar-item-height: 40px;