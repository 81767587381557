@import "variables";
@import "shared/components";

html, body{
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: $default-font;
  color: $default-font-color;
  position: relative;
}

html{
  height: 100%;
}

body{
  min-height:100%;
  display: flex;
  flex-direction: column;
}

body.restrict{
  overflow: hidden;
}

header, footer, nav, section, div, aside{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@mixin expanding-container{
  flex: 1;
  display: flex;
  flex-direction: column;
}

.content{
  width: 100%;
  max-width: $max-site-width;
  margin: 0 auto;
}

#main{
  @include expanding-container();
  position: relative;
  width: 100%;
  margin: 0 auto;

  #main-content{
    padding-top: $nav-height;
    height: 100%;
    @include expanding-container();

    #page-content{
      @include expanding-container();
    }
  }
}

input{
  font-family: $default-font;
}

section{
  padding: 10px;
}

@import "app/components/button";

@import "app/components/nav";
@import "app/components/sidebar";
@import "app/components/searchbar";
@import "app/components/footer";