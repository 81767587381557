@mixin clear{
  content: '';
  display: block;
  clear: both;
}

@mixin reset-link{
  text-decoration: none;
  color: unset;
}

@mixin proper-block{
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@mixin proper-flex{
  display: flex;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@mixin flex-static($basis){
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: $basis;
}