.hk-btn{
  text-decoration: none;
  padding: 5px 15px;
  border: 1px solid #ccc;
  color: #333;
  border-radius: 5px;
  font-size: 14px;
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.hk-sq-btn{
  text-decoration: none;
  padding: 5px 15px;
  border: 1px solid #ccc;
  color: #333;
  font-size: 14px;
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}