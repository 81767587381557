@import "../../_variables";
@import "../../shared/_components";

nav{
  width: 100%;
  max-width: inherit;
  padding: $nav-padding;
  height: $nav-height;
  background-color: $nav-bg-color;
  color: #fff;
  z-index: 800;
  position: fixed;

  #sidebar-open-btn-container{
    width: 35px;
    height: 100%;
    float: left;
    margin-right: 10px;

    #sidebar-open-btn{
      width: 100%;
      height: 100%;
      display: block;
      text-align: center;
      line-height: $nav-line-height;
    }
  }

  #order-btn-container{
    width: 70px;
    margin-left: 10px;
    line-height: $nav-line-height;
    float: right;

    a{
      height: 30px;
      width: 100%;
      color: #fff;
      border: 1px solid #fff;
      line-height: normal;
    }
  }

  header{
    a{
      @include reset-link();
    }
    margin-left: 45px;
    margin-right: 80px;
    line-height: $nav-line-height;
    height: 100%;

    #logo{
      height: 100%;

      .logo-image{
        float: left;
        height: 35px;
        width: 35px;
      }

      .logo-text{
        width: 100%;
        padding-left: 40px;
      }

      img{
        max-width:100%;
        max-height: 100%;
      }
    }
  }

  &:after{
    @include clear();
  }
}