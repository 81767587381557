@import "../../shared/_components";
@import "../../_variables";

.back-to-top{
  margin-top: 10px;
  width: 100%;
  padding: 5px;
  background-color: $back-to-top-bg-color;
  color: #fff;

  #back-to-top-link{
    @include reset-link();
    width: 100%;

    .back-to-top-link-content, .marker, .text{
      width: 100%;
      text-align: center;
      font-size: 14px;
    }
  }
}

footer{
  width: 100%;
  padding: 10px;
  background-color: $footer-bg-color;
  color: #fff;
  .content{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footer-logo{
    height: 50px;
    width: 50px;
    margin-right: 5px;
    img{
      display: block;
      max-height: 100%;
      max-width: 100%;
    }
  }
  .company-name{
  }
  .year{
    font-size: 12px;
  }
}