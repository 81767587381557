@import "../../_variables";
@import "../../shared/_components";

#search-bar{
  width: 100%;
  padding: 10px;
  background-color: $nav-bg-color;
  flex-shrink: 0;
  flex-grow: 0;

  form{
    width: 100%;
    height: 30px;
  }

  .search-textbox{
    width: 80%;
    height: 100%;
    float: left;
  }

  .search-btn{
    width: 20%;
    height: 100%;
    float: right;
  }

  &:after{
    @include clear();
  }

  input{
    height: 100%;
    width: 100%;
    font-size: 14px;
  }

  input[type=text]{
    border-radius: 5px 0 0 5px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 10px;
    border: 1px solid #ccc;
  }

  input[type=submit]{
    border-radius: 0 5px 5px 0;
    border: 1px solid #ccc;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    //padding: 0 10px;
    text-align: center;
  }
}